// BUTTON
@import "core";

@include respond-to(base-inline) {
  // default
  :where(.tc_button){position:relative;display:inline-flex;justify-content:center;align-items:center;gap:var(--spacing-8);padding:0 calc(var(--spacing-24));font-size:var(--font-16);font-weight:var(--font-medium);line-height:var(--line-110);box-sizing:border-box;height:var(--spacing-40);border:0;border-radius:calc(var(--spacing-40) / 2);margin:0;text-decoration:none;white-space:nowrap;text-align:center;max-width:100%;background-color:transparent;cursor:pointer;
    &[disabled],&.disabled{cursor:default;}
    &:active:not(.tc_button--link):not([disabled]):not(.disabled){
      &:not(.tc_button--icon){scale:var(--button-active-scale);}
      &.tc_button--icon .tc_button__text,&.tc_button--icon .tc_button__icon{scale:var(--button-active-scale);}
    }
    &:not(.tc_button--icon){
      &[data-icon-position="left"] .tc_button__icon{margin-left:calc(var(--spacing-8) * -1);}
      &[data-icon-position="right"] .tc_button__icon{margin-right:calc(var(--spacing-8) * -1);}
    }
    &.tc_button--has-icon-only{aspect-ratio:1/1;padding:0;max-width:fit-content; // max-width is a fix for Safari flexbox bug
      .tc_button__icon svg{position:static;}
    }
    .tc_button__icon{position:relative;
      svg{width:var(--spacing-20);height:var(--spacing-20);}
    }
    span{pointer-events:none;}
  }

  // primary
  :where(.tc_button--primary:not(.tc_button--icon)){background-color:var(--color-dark);color:var(--color-light);
    &.tc_button--light{background-color:var(--color-light);color:var(--color-text);
      &[disabled],&.disabled{background-color:var(--color-800);color:var(--color-500);}
    }
    &.tc_button--tv2red{background-color:var(--color-primary-tv2red);color:var(--color-white);}
    &.tc_button--blue{background-color:var(--color-primary-blue);color:var(--color-white);}
    &.tc_button--green{background-color:var(--color-primary-green);color:var(--color-white);}
    &.tc_button--violet{background-color:var(--color-primary-violet);color:var(--color-white);}
    &[disabled],&.disabled{background-color:var(--color-300);color:var(--color-500);}
  }

  // secondary
  :where(.tc_button--secondary:not(.tc_button--icon)){background-color:var(--color-dark-200);color:var(--color-text);
    &[disabled],&.disabled{background-color:var(--color-100);color:var(--color-400);}
    &.tc_button--light{background-color:var(--color-light-200);color:var(--color-light);
      &[disabled],&.disabled{background-color:var(--color-800);color:var(--color-500);}
    }
  }

  // tertiary
  :where(.tc_button--tertiary){color:var(--color-text);
    &.tc_button--light{color:var(--color-light);}
    &[disabled],&.disabled{color:var(--color-400);}
  }

  // on-image
  :where(.tc_button--on-image){background-color:var(--color-dark-700);color:var(--color-light);
    &[disabled],&.disabled{background-color:var(--color-100);color:var(--color-400);}
    &:active{background-color:var(--color-300);color:var(--color-text);}
  }

  // link
  :where(.tc_button--link){font-size:var(--font-18);font-weight:var(--font-regular);height:auto;line-height:normal;padding:0;overflow:visible;text-decoration:underline;color:var(--color-link);white-space:normal;border:0;border-radius:0;background-color:transparent;}

  // sizes
  :where(.tc_button--xs){height:var(--spacing-24);border-radius:calc(var(--spacing-24) / 2);font-size:var(--font-12);gap:var(--spacing-4);
    .tc_button__icon svg{width:var(--spacing-12);height:var(--spacing-12);}
    &:where(:not(.tc_button--icon)){
      &:where(:not(.tc_button--link):not(.tc_button--has-icon-only)){padding:0 calc(var(--spacing-12));}
      &[data-icon-position="left"] .tc_button__icon{margin-left:calc(var(--spacing-4) * -1);}
      &[data-icon-position="right"] .tc_button__icon{margin-right:calc(var(--spacing-4) * -1);}
    }
  }
  :where(.tc_button--s){height:var(--spacing-32);border-radius:calc(var(--spacing-32) / 2);font-size:var(--font-14);gap:var(--spacing-8);
    .tc_button__icon svg{width:var(--spacing-16);height:var(--spacing-16);}
    &:where(:not(.tc_button--icon)){
      &:where(:not(.tc_button--link):not(.tc_button--has-icon-only)){padding:0 calc(var(--spacing-16));}
      &[data-icon-position="left"] .tc_button__icon{margin-left:calc(var(--spacing-4) * -1);}
      &[data-icon-position="right"] .tc_button__icon{margin-right:calc(var(--spacing-4) * -1);}
    }
  }
  :where(.tc_button--s-m){height:var(--spacing-32-step);border-radius:calc(var(--spacing-32-step) / 2);font-size:var(--font-14-16);gap:var(--spacing-8);
    .tc_button__icon svg{width:calc(var(--spacing-12) + var(--spacing-4-step));height:calc(var(--spacing-12) + var(--spacing-4-step));}
    &:where(:not(.tc_button--icon)){
      &:where(:not(.tc_button--link):not(.tc_button--has-icon-only)){padding:0 calc(var(--spacing-16-step));}
      &[data-icon-position="left"] .tc_button__icon{margin-left:calc(var(--spacing-4-step) * -1);}
      &[data-icon-position="right"] .tc_button__icon{margin-right:calc(var(--spacing-4-step) * -1);}
    }
    .tc_button--icon:has(.tc_button__icon):not(.tc_button--link):not(.tc_button--tertiary){gap:calc(var(--spacing-4) + var(--spacing-4-step));}
  }
  :where(.tc_button--m-l){height:var(--spacing-40-step);border-radius:calc(var(--spacing-40-step) / 2);font-size:var(--font-16-18);
    .tc_button__icon svg{width:calc(var(--spacing-16) + var(--spacing-4-step));height:calc(var(--spacing-16) + var(--spacing-4-step));}
    &:where(:not(.tc_button--icon):not(.tc_button--link):not(.tc_button--has-icon-only)){padding:0 calc(var(--spacing-24-step));}
  }
  :where(.tc_button--l){height:var(--spacing-48);border-radius:calc(var(--spacing-48) / 2);font-size:var(--font-18);
    .tc_button__icon svg{width:var(--spacing-24);height:var(--spacing-24);}
    &:where(:not(.tc_button--icon):not(.tc_button--link):not(.tc_button--has-icon-only)){padding:0 calc(var(--spacing-32));}
  }

  // icon button
  :where(.tc_button--icon:not(.tc_button--link):not(.tc_button--tertiary)){gap:var(--spacing-12);padding:0 var(--spacing-12) 0 0;background-color:transparent;color:var(--color-text);
    .tc_button__icon{background-color:var(--color-text);color:var(--color-light);display:flex;justify-content:center;align-items:center;height:var(--spacing-40);aspect-ratio:1;border-radius:50%;}

    // icon button - primary
    &.tc_button--primary{
      &.tc_button--light{color:var(--color-light);
        .tc_button__icon{background-color:var(--color-light);color:var(--color-text);}
        &[disabled],&.disabled{color:var(--color-700);
          .tc_button__icon{background-color:var(--color-700);color:var(--color-600);}
        }
      }
      &.tc_button--tv2red .tc_button__icon{background-color:var(--color-primary-tv2red);color:var(--color-white);}
      &.tc_button--blue .tc_button__icon{background-color:var(--color-primary-blue);color:var(--color-white);}
      &.tc_button--green .tc_button__icon{background-color:var(--color-primary-green);color:var(--color-white);}
      &.tc_button--violet .tc_button__icon{background-color:var(--color-primary-violet);color:var(--color-white);}
      &[disabled],&.disabled{color:var(--color-500);
        .tc_button__icon{background-color:var(--color-300);color:var(--color-500);}
      }
    }

    // icon button - secondary
    &.tc_button--secondary{
      .tc_button__icon{background-color:var(--color-dark-200);color:var(--color-text);}
      &.tc_button--light{color:var(--color-light);
        .tc_button__icon{background-color:var(--color-light-200);color:var(--color-light);}
      }
      &[disabled],&.disabled{color:var(--color-500);
        .tc_button__icon{color:var(--color-500);}
      }
    }

    // icon button - on-image
    &.tc_button--on-image{
      .tc_button__icon{background-color:var(--color-dark-700);color:var(--color-light);}
      &[disabled],&.disabled{color:var(--color-500);
        .tc_button__icon{background-color:var(--color-100);color:var(--color-500);}
      }
      &:active .tc_button__icon{background-color:var(--color-300);color:var(--color-text);}
    }

    // icon button - sizes
    &.tc_button--xs{gap:var(--spacing-4);
      .tc_button__icon{height:var(--spacing-24);}
    }
    &.tc_button--s{gap:var(--spacing-8);
      .tc_button__icon{height:var(--spacing-32);}
    }
    &.tc_button--s-m{gap:calc(var(--spacing-4) + var(--spacing-4-step));
      .tc_button__icon{height:var(--spacing-32-step);}
    }
    &.tc_button--l .tc_button__icon{height:var(--spacing-48);}
  }
}

@include respond-to(base) {
  :where(.tc_button[data-error-message]:not([data-error-message=""]))::after{content:attr(data-error-message);position:absolute;top:100%;left:0;right:0;z-index:10;padding:var(--spacing-8) 0 0;white-space:normal;font-size:var(--font-12);font-weight:var(--font-regular);line-height:var(--line-120);color:var(--color-error);}
  .tc_button--loading{cursor:wait;
    &:not(.tc_button--icon){color:transparent;-webkit-text-fill-color:transparent;
      &::before{content:"";position:absolute;left:0;right:0;top:0;bottom:0;mask:url("inline!../../../../icons/spinner.svg") no-repeat 50% 50%;mask-size:var(--spacing-24);background-color:var(--color-light);}
      &:is(.tc_button--light.tc_button--primary,.tc_button--dark.tc_button--secondary,.tc_button--dark.tc_button--tertiary)::before{background-color:var(--color-dark);}
      &:is(.tc_button.tc_button--tv2red,.tc_button.tc_button--blue,.tc_button.tc_button--green,.tc_button.tc_button--violet)::before{background-color:var(--color-white);}
    }

    // icon button
    &.tc_button--icon:not(.tc_button--link):not(.tc_button--tertiary){
      .tc_button__icon{color:transparent;
        &::before{content:"";position:absolute;left:0;right:0;top:0;bottom:0;mask:url("inline!../../../../icons/spinner.svg") no-repeat 50% 50%;mask-size:var(--spacing-24);background-color:var(--color-light);}
      }
      &:is(.tc_button--light.tc_button--primary,.tc_button--dark.tc_button--secondary){
        .tc_button__icon::before{background-color:var(--color-dark);}
      }
      &.tc_button--primary:is(.tc_button.tc_button--tv2red,.tc_button.tc_button--blue,.tc_button.tc_button--green,.tc_button.tc_button--violet){
        .tc_button__icon::before{background-color:var(--color-white);}
      }
    }
  }

  // forced theme
  :is(.tc_deck--bg-900,.tc_deck--bg-midnight,.tc_deck--bg-tv2red,.tc_deck--bg-blue,.tc_deck--bg-green,.tc_deck--bg-violet,.tc_deck--bg-dark-bordeaux,.tc_deck--bg-dark-nightblue,.tc_deck--bg-dark-forestgreen,.tc_deck--bg-live){
    .tc_button.tc_button--loading:not(.tc_button--icon).tc_button--dark{
      &::before{background-color:var(--color-dark);}
    }
  }
}

@include respond-to(hover) {
  :where(.tc_button:not([disabled]):not(.disabled)){transition:color var(--transition-normal), background-color var(--transition-normal);
    .tc_button__icon{transition:color var(--transition-normal), background-color var(--transition-normal);}
    &:hover{
      &:where(.tc_button--primary:not(.tc_button--icon)){
        &.tc_button--dark{background-color:var(--color-800);}
        &.tc_button--light{background-color:var(--color-200);}
        &.tc_button--tv2red{background-color:var(--color-interactive-tv2red);}
        &.tc_button--blue{background-color:var(--color-interactive-blue);}
        &.tc_button--green{background-color:var(--color-interactive-green);}
        &.tc_button--violet{background-color:var(--color-interactive-violet);}
      }
      &:where(.tc_button--secondary:not(.tc_button--icon), .tc_button--tertiary:not(.tc_button--icon)){
        &.tc_button--dark{background-color:var(--color-dark-100);}
        &.tc_button--light{background-color:var(--color-light-100);}
      }
      &:where(.tc_button--on-image:not(.tc_button--icon):not(:active)){background-color:var(--color-light);color:var(--color-text);}
      &:where(.tc_button--link){transition:color var(--transition-faster) var(--link-easing-hover);color:var(--color-link-hover);}

      // icon button
      &:where(.tc_button--icon:not(.tc_button--link)){
        &:where(.tc_button--primary){
          &.tc_button--dark .tc_button__icon{background-color:var(--color-800);}
          &.tc_button--light .tc_button__icon{background-color:var(--color-200);}
          &.tc_button--tv2red .tc_button__icon{background-color:var(--color-interactive-tv2red);}
          &.tc_button--blue .tc_button__icon{background-color:var(--color-interactive-blue);}
          &.tc_button--green .tc_button__icon{background-color:var(--color-interactive-green);}
          &.tc_button--violet .tc_button__icon{background-color:var(--color-interactive-violet);}
        }
        &:where(.tc_button--secondary){
          &.tc_button--dark .tc_button__icon{background-color:var(--color-dark-100);}
          &.tc_button--light .tc_button__icon{background-color:var(--color-light-200);}
        }
        &:where(.tc_button--on-image:not(:active)){
          .tc_button__icon{background-color:var(--color-light);color:var(--color-text);}
        }
      }
    }
  }
}

@include respond-to(print) {
  .tc_button{display:none;}
}
